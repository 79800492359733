import '../../../services/configLeaflet';
import 'leaflet.markercluster';
import './CatMap.css';

import axios from 'axios';
import L from 'leaflet';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import {
    LayerGroup, LayersControl, Map, Polygon, TileLayer, Tooltip, WMSTileLayer
} from 'react-leaflet';
import HeatMapLayer from 'react-leaflet-heatmap-layer';
import * as turf from 'turf';

import Cards from './components/Cards';
import Sidebar from './components/Sidebar';
import { pnwBounds, txBounds } from './consts';
import { CatUser } from './types';
import { padNumber } from './utils';

const ENABLE_FIRE_DEMO = true;

const CatMap = () => {
  const [users, setUsers] = useState<null | CatUser[]>(null);
  const mapRef = useRef<Map>(null);
  const fireLayerGroupRef = useRef<LayerGroup | null>(null);

  useEffect(() => {
    async function getDataPoints() {
      const users = await axios.get(
        'https://assured-cat.s3.amazonaws.com/demoUsers/demoUsersFinal.json',
      );

      let fires: any;
      if (ENABLE_FIRE_DEMO) {
        fires = await axios.get(
          'https://assured-cat.s3.amazonaws.com/demoFire/fireDump.json',
        );
      }

      users.data.forEach((u: CatUser) => {
        u.latitude += (Math.random() - 0.5) * 0.1;
        u.longitude += (Math.random() - 0.5) * 0.1;
      });
      setUsers(users.data);

      const showClustered = true;
      if (showClustered) {
        setTimeout(() => {
          const map = mapRef.current?.leafletElement;
          if (!map) {
            window.location.reload();
            return;
          }

          const markers = L.markerClusterGroup({
            singleMarkerMode: true,
            chunkedLoading: true,
            spiderfyOnMaxZoom: false,
            polygonOptions: { color: 'orange' },
            maxClusterRadius: zoom => {
              if (zoom > 12) {
                return 150;
              }
              return 80;
            },
            iconCreateFunction: cluster => {
              const childCount = cluster.getChildCount();
              let c = ' marker-cluster-';
              const newChildCount = padNumber(childCount);
              if (newChildCount < 10) {
                c += 'small';
              } else if (newChildCount < 100) {
                c += 'medium';
              } else {
                c += 'large';
              }

              return new L.DivIcon({
                html: `<div><span class='CatMap-count'>${newChildCount.toLocaleString()}</span></div>`,
                className: 'marker-cluster' + c,
                iconSize: new L.Point(40, 40),
              });
            },
          });

          // Chunked load markers
          const nMarkers = users.data.length;
          const chunkSize = 15000;
          const nChunks = Math.ceil(nMarkers / chunkSize);
          for (let i = 0; i < nChunks; i++) {
            setTimeout(() => {
              markers.addLayers(
                users.data
                  .slice(i * chunkSize, (i + 1) * chunkSize)
                  .map((u: CatUser) => L.marker([u.latitude, u.longitude])),
              );
            }, i * 500);
          }

          markers
            .on('clustermouseover', c => {
              const loc = c.layer.getLatLng();
              const dist = loc.distanceTo({
                lat: 32.96024595000238,
                lng: -90.71962594985962,
              });
              const impactProb = Math.max(
                100 - dist / 10000,
                1 + loc.lat / 100,
              );

              const points = c.layer
                .getAllChildMarkers()
                .map((m: any) => m.getLatLng())
                .map((p: any) => turf.point([p.lat, p.lng]));

              if (points.length < 3) {
                return;
              }

              const area = turf.area(
                turf.convex(turf.featureCollection(points)),
              );

              L.popup({
                closeButton: false,
              })
                .setLatLng(loc)
                .setContent(
                  `
                <div class="font-sans text-center">
                  <div class="font-bold">
                  ${padNumber(
                    c.layer._childCount,
                  ).toLocaleString()} Active Policies
                  </div>
                  <div>
                    ${impactProb.toFixed(2)}% Impact Probability
                  </div>
                  <div>
                    ${(area / (2.59 * 10 ** 6)).toFixed(2)} sq. mi.
                  </div>
                </div>
                `,
                )
                .openOn(map);
            })
            .on('clustermouseout', function (c) {
              map.closePopup();
            })
            .on('clusterclick', function (c) {
              map.closePopup();
            });

          map.addLayer(markers);
        }, 50);
      }

      if (fires) {
        setTimeout(() => {
          const map = mapRef.current?.leafletElement;
          if (!map) {
            window.location.reload();
            return;
          }

          // Chunked load markers
          const entries = fires.data.data.fires.filter((f: any) => {
            return (
              f.details?.status === 'Active' &&
              f.details?.size?.value &&
              f.details?.fire_type !== 'Prescribed Fire' &&
              f.details?.time_discovered &&
              new Date(f.details?.time_discovered) >
                moment().subtract(14, 'days').toDate() &&
              f.details?.size?.value > 4000
            );
          });
          const nMarkers = entries.length;
          const chunkSize = 20000;
          const nChunks = Math.ceil(nMarkers / chunkSize);

          const addLayers = (layers: L.Layer[]) => {
            for (const l of layers) {
              fireLayerGroupRef.current?.leafletElement.addLayer(l);
            }
          };

          for (let i = 0; i < nChunks; i++) {
            setTimeout(() => {
              addLayers(
                entries
                  .slice(i * chunkSize, (i + 1) * chunkSize)
                  .map((e: any) => {
                    const details = [
                      {
                        label: 'Status',
                        value: e.details?.status,
                      },
                      {
                        label: 'Time discovered',
                        value: e.details?.time_discovered
                          ? new Date(
                              e.details?.time_discovered,
                            ).toLocaleString()
                          : null,
                      },
                      {
                        label: 'Fire type',
                        value: e.details?.fire_type,
                      },
                      {
                        label: 'Fire cause',
                        value: e.details?.fire_cause,
                      },
                      {
                        label: 'Percent contained',
                        value: e.details?.percent_contained
                          ? `${e.details.percent_contained}%`
                          : null,
                      },
                      {
                        label: 'Fire size',
                        value: e.details?.size?.value
                          ? `${(e.details.size.value / 4047) /* m2 -> acres */
                              .toFixed(2)} acres`
                          : null,
                      },
                      {
                        label: 'Confirmed by',
                        value: e.source,
                      },
                    ].filter(e => !!e.value);

                    return L.marker([e.position.lat, e.position.lon], {
                      icon: L.divIcon({
                        iconSize: [25, 25],
                        iconAnchor: [25 / 2, 25 + 9],
                        className: 'CatMap-FireMarker',
                        html: '🔥',
                      }),
                    }).bindTooltip(
                      `
                      <div class="font-sans text-center p-2">
                        <div class="font-bold">
                          ${e.details?.fire_name || 'Unnamed Fire Event'}
                        </div>
                        ${details
                          .map(({ label, value }) => {
                            return `
                            <div class="text-left">${label}: ${value}</div>
                          `;
                          })
                          .join('')}
                      </div>
                      `,
                      { direction: 'bottom' },
                    );
                  }),
              );
            }, i * 500);
          }
        }, 50);
      }
    }

    getDataPoints();
  }, []);

  return (
    <div className="pt-10 px-6 max-w-screen-xl mx-auto">
      <div className="flex">
        <Map
          ref={mapRef}
          className="rounded shadow overflow-hidden z-0"
          style={{ flex: 1 }}
          center={[39.8097343, -98.5556199 - 5]}
          zoom={4}
          attributionControl={false}
          scrollWheelZoom={false}
          maxZoom={12}
        >
          <TileLayer url="https://api.mapbox.com/styles/v1/assured/ckmqt1p8l104l17k1mfp5oosg/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYXNzdXJlZCIsImEiOiJjazNnMzdoOGMwYmVsM2Nsamx5d3l1aWJ5In0.42oThFdnBaMOFXtILW8u-Q" />
          {users ? (
            <HeatMapLayer
              points={users}
              latitudeExtractor={(u: CatUser) => u.latitude}
              longitudeExtractor={(u: CatUser) => u.longitude}
              gradient={{
                0.1: '#D5D5D6',
                1: '#D79B00',
              }}
              intensityExtractor={() => 1}
              radius={3}
              blur={3}
              max={0.4}
            />
          ) : null}

          <LayersControl position="topright">
            <LayersControl.Overlay name="Active Fires">
              <LayerGroup
                ref={r => {
                  fireLayerGroupRef.current = r;
                }}
              ></LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Fire Risk">
              <LayerGroup>
                <WMSTileLayer
                  url={`https://dmsdata.cr.usgs.gov/geoserver/wms?SRS=EPSG%3A3857&jsonLayerId=fpi&TIME=2021-04-03`}
                  layers="firedanger_emodis_fpi_conus_daily_data:emodis_fpi_conus_daily_data"
                  styles="firedanger_emodis_daily_fpi_conus_raster"
                  transparent={true}
                  format="image/png"
                  version="1.3.0"
                  opacity={0.5}
                />
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name="Active Precipitation - Snow">
              <LayerGroup>
                <TileLayer
                  url={`http://maps.openweathermap.org/maps/2.0/weather/PAS0/{z}/{x}/{y}?date=1613375826&appid=${process.env.REACT_APP_OPEN_WEATHER_API_KEY}`}
                />
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Active Precipitation - All">
              <LayerGroup>
                <TileLayer
                  url={`https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=${process.env.REACT_APP_OPEN_WEATHER_API_KEY}`}
                />
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Active Precipitation - Rain">
              <LayerGroup>
                <TileLayer
                  url={`https://maps.openweathermap.org/maps/2.0/weather/PAR0/{z}/{x}/{y}?appid=${process.env.REACT_APP_OPEN_WEATHER_API_KEY}`}
                />
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Air Temperature">
              <LayerGroup>
                <TileLayer
                  url={`https://maps.openweathermap.org/maps/2.0/weather/TA2/{z}/{x}/{y}?appid=${process.env.REACT_APP_OPEN_WEATHER_API_KEY}&palette=0:E1C86400;0.1:C8963200;0.2:9696AA00;0.5:7878BE00;1:6E6ECD4C;10:5050E1B2;140:1414FFE5&date=1613375826`}
                />
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Convective Precipitation">
              <LayerGroup>
                <TileLayer
                  url={`https://maps.openweathermap.org/maps/2.0/weather/PAC0/{z}/{x}/{y}?appid=${process.env.REACT_APP_OPEN_WEATHER_API_KEY}&palette=0:E1C86400;0.1:C8963200;0.2:9696AA00;0.5:7878BE00;1:6E6ECD4C;10:5050E1B2;140:1414FFE5&date=1613375826`}
                />
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Cloudiness">
              <LayerGroup>
                <TileLayer
                  url={`https://maps.openweathermap.org/maps/2.0/weather/CL/{z}/{x}/{y}?appid=${process.env.REACT_APP_OPEN_WEATHER_API_KEY}&date=1613375826`}
                />
              </LayerGroup>
            </LayersControl.Overlay>
          </LayersControl>
          <Polygon positions={txBounds}>
            <Tooltip direction="bottom" offset={[0, 60]} opacity={1} permanent>
              <div className="font-sans text-center">
                <div className="font-bold">CAT-S2SJ29: "Winter Storm Uri"</div>
                <div className="my-1" style={{ fontSize: 10 }}>
                  <span className="px-2 py-1 text-white bg-blue-500 rounded font-bold">
                    Winter storm
                  </span>
                  <span className="ml-1 px-2 py-1 text-white bg-red-500 rounded font-bold">
                    High Severity
                  </span>
                </div>
                <div>Users engaged: 1,422,483</div>
              </div>
            </Tooltip>
          </Polygon>

          <Polygon positions={pnwBounds}>
            <Tooltip direction="left" offset={[-20, 0]} opacity={1} permanent>
              <div className="font-sans text-center">
                <div className="font-bold">CAT-A1SN21: Unnamed event</div>
                <div className="my-1" style={{ fontSize: 10 }}>
                  <span className="px-2 py-1 text-white bg-blue-500 rounded font-bold">
                    Hailstorm
                  </span>
                  <span className="ml-1 px-2 py-1 text-white bg-gray-400 rounded font-bold">
                    Low Severity
                  </span>
                </div>
                <div>Users engaged: 5,209</div>
              </div>
            </Tooltip>
          </Polygon>
        </Map>
        <div className="w-1/5 ml-10 flex flex-col gap-4 justify-start">
          <Sidebar />
        </div>
      </div>
      <div className="mt-10 pb-10">
        <Cards />
      </div>
    </div>
  );
};

export default CatMap;
