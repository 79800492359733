import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { RepairCoordinationStepComponent } from '@common/types/ClaimWorkflow';
import computeDistance from '@turf/distance';

import Modal from '../../Modal';
import { StepComponentFC, StepComponentSharedProps } from '../types/stepComponentTypes';

interface RepairCoordinationProps
  extends StepComponentSharedProps<
    RepairCoordinationStepComponent,
    {
      vehicleBodyShopState: string | null;
      vehicleRequestedBodyShop: string | null;
    } | null
  > {
  forceSubmit: () => void;
}
const RepairCoordination: StepComponentFC<RepairCoordinationProps> = ({
  step_component,
  updateValue,
  className,
  forceSubmit,
}) => {
  let shops = (step_component.shops || DEFAULT_SHOPS)
    .map(shop => {
      const distance = step_component.estimated_location
        ? computeDistance(
            [shop.latitude, shop.longitude],
            [
              step_component.estimated_location.latitude,
              step_component.estimated_location.longitude,
            ],
            { units: 'miles' },
          )
        : null;
      return { ...shop, distance };
    })
    .sort((a, b) => {
      return (a.distance || 0) - (b.distance || 0);
    })
    .map(shop => {
      return {
        ...shop,
        distance: `${(+(shop.distance || 0).toFixed(
          2,
        )).toLocaleString()} miles away`,
      };
    })
    .slice(0, 5);

  const [selectedShop, setSelectedShop] = useState<typeof shops[number] | null>(
    null,
  );
  const [showSelectedShop, setShowSelectedShop] = useState(false);

  const [hasFailedDueToNoShops, setHasFailedDueToNoShops] = useState(false);
  useEffect(() => {
    // Auto-skip if no results found
    if (!hasFailedDueToNoShops && shops.length === 0) {
      setHasFailedDueToNoShops(true);
      forceSubmit();
    }
  }, [shops, hasFailedDueToNoShops]);

  return (
    <div className={classNames('mt-12', className)}>
      <Modal
        title={selectedShop?.name}
        actions={[
          {
            title: 'Back',
            primary: false,
            onClick: () => setShowSelectedShop(false),
          },
          {
            title: 'Confirm',
            primary: true,
            onClick: () => {
              setShowSelectedShop(false);
              if (step_component.field) {
                updateValue(step_component.field, {
                  vehicleBodyShopState: 'REQUESTS_BODY_SHOP',
                  vehicleRequestedBodyShop: JSON.stringify(selectedShop),
                });
              } else {
                forceSubmit();
              }
            },
          },
        ]}
        open={showSelectedShop}
        customDismiss={() => setShowSelectedShop(false)}
        body={
          <div className="px-4">
            <img
              width={640}
              height={420}
              className="mt-4 rounded-md overflow-hidden"
              src={`https://maps.googleapis.com/maps/api/staticmap?zoom=17&size=640x420&maptype=roadmap&markers=${selectedShop?.latitude},${selectedShop?.longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&__v=1`}
            />
            <div className="mt-6 mb-6 text-left">
              <div className="float-right text-right">
                <div>
                  <a
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      `${selectedShop?.name}, ${selectedShop?.address_line_1}, ${selectedShop?.address_line_2}`,
                    )}`}
                    className="w-20 inline-flex items-center px-3 py-1 shadow-sm border border-blue-300 text-sm leading-5 font-medium rounded-full text-blue-700 bg-white focus:outline-none"
                  >
                    <svg
                      className="-ml-0.5 mr-2 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                      />
                    </svg>
                    Map
                  </a>
                </div>
                <div className="mt-2">
                  <a
                    href={`tel:${selectedShop?.phone_number}`}
                    className="w-20 inline-flex items-center px-3 py-1 shadow-sm border border-blue-300 text-sm leading-5 font-medium rounded-full text-blue-700 bg-white focus:outline-none"
                  >
                    <svg
                      className="-ml-0.5 mr-2 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>{' '}
                    Call
                  </a>
                </div>
              </div>
              <p className="text-sm text-gray-500">{selectedShop?.distance}</p>
              <p className="mt-2 text-sm text-gray-700">
                {selectedShop?.address_line_1}
              </p>
              <p className="text-sm text-gray-700">
                {selectedShop?.address_line_2}
              </p>
            </div>
          </div>
        }
      />
      <div>
        <div className="flow-root text-left">
          <ul className="-my-5 divide-y divide-gray-200">
            {shops.map((shop, i) => (
              <li
                key={shop.name}
                className="py-4"
                onClick={() => {
                  setSelectedShop(shop);
                  setShowSelectedShop(true);
                }}
              >
                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <div className="h-8 w-8 rounded-full bg-blue-600 inline-flex items-center justify-center">
                      <span className="font-medium text-white">
                        {String.fromCharCode(65 + i)}
                      </span>
                    </div>
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900">
                      {shop.name}
                    </p>
                    {/* {shop.badge ? (
                      <div>
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                          {shop.badge}
                        </span>
                      </div>
                    ) : null} */}
                    {shop.distance ? (
                      <p className="text-sm text-gray-500">{shop.distance}</p>
                    ) : null}
                  </div>
                  <div className="text-right">
                    <p>
                      <button
                        className="ml-2 inline-flex items-center shadow-sm px-2.5 py-0.5 border border-blue-300 text-sm leading-5 font-medium rounded-full text-blue-700 bg-white hover:bg-blue-50 focus:shadow-outline-blue focus:outline-none"
                        onClick={() => {
                          setSelectedShop(shop);
                          setShowSelectedShop(true);
                        }}
                      >
                        Select
                      </button>
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {step_component.tenant === 'mercury' ? (
        <div className="mt-12 text-xs font-medium text-cool-gray-500">
          WE ARE PROHIBITED BY LAW FROM REQUIRING THAT REPAIRS BE DONE AT A
          SPECIFIC AUTOMOTIVE REPAIR DEALER. YOU ARE ENTITLED TO SELECT THE AUTO
          BODY REPAIR SHOP TO REPAIR DAMAGE COVERED BY US. WE HAVE RECOMMENDED
          AN AUTOMOTIVE REPAIR DEALER THAT WILL REPAIR YOUR DAMAGED VEHICLE. WE
          RECOMMEND YOU CONTACT ANY OTHER AUTOMOTIVE REPAIR DEALER YOU ARE
          CONSIDERING TO CLARIFY ANY QUESTIONS YOU MAY HAVE REGARDING SERVICES
          AND BENEFITS. IF YOU AGREE TO USE OUR RECOMMENDED AUTOMOTIVE REPAIR
          DEALER, WE WILL CAUSE THE DAMAGED VEHICLE TO BE RESTORED TO ITS
          CONDITION PRIOR TO THE LOSS AT NO ADDITIONAL COST TO YOU OTHER THAN AS
          STATED IN THE INSURANCE POLICY OR AS OTHERWISE ALLOWED BY LAW. IF YOU
          EXPERIENCE A PROBLEM WITH THE REPAIR OF YOUR VEHICLE, PLEASE CONTACT
          US IMMEDIATELY FOR ASSISTANCE.
        </div>
      ) : (
        <div className="mt-12 text-xs font-medium text-cool-gray-500">
          PURSUANT TO SECTION 2610 OF THE INSURANCE LAW, AN INSURANCE COMPANY
          CANNOT REQUIRE THAT REPAIRS BE MADE TO A MOTOR VEHICLE IN A PARTICULAR
          PLACE OR REPAIR SHOP. YOU HAVE THE RIGHT TO HAVE YOUR VEHICLE REPAIRED
          IN THE SHOP OF YOUR CHOICE.
        </div>
      )}
      <div className="mt-8 text-center">
        <button
          className="btn btn-subtle"
          onClick={() => {
            updateValue(step_component.field, {
              vehicleBodyShopState: null,
              vehicleRequestedBodyShop: null,
            });
          }}
        >
          No thanks
        </button>
      </div>
    </div>
  );
};

export default RepairCoordination;

const DEFAULT_SHOPS = [
  {
    name: 'Akins Palo Alto Inc',
    badge: 'Direct Repair Program',
    address_line_1: '3290 Park Blvd',
    address_line_2: 'Palo Alto, CA 94306',
    phone_number: '+16503211460',
    latitude: 37.42380969581101,
    longitude: -122.13478163186996,
  },
  {
    name: 'Towne Auto Body',
    address_line_1: '111 Cedar St',
    address_line_2: 'Redwood City, CA 94063',
    phone_number: '(650) 366-7211',
    latitude: 37.47986802742939,
    longitude: -122.22503502829093,
  },
  {
    name: 'Golden West Collision Center',
    address_line_1: '970 West Evelyn Avenue',
    address_line_2: 'Sunnyvale, CA 94086',
    phone_number: '(408) 636-1300',
    latitude: 37.382492913798814,
    longitude: -122.04405650682561,
  },
  {
    name: 'Caliber - Mountain View',
    address_line_1: '177 E Evelyn Ave',
    address_line_2: 'Mountain View, CA 94041',
    phone_number: '(650) 965-1440',
    latitude: 37.38915632551267,
    longitude: -122.13478163186996,
  },
  {
    name: 'Caliber - Redwood City - North',
    address_line_1: '1800 Industrial Way',
    address_line_2: 'Redwood City, CA 94063',
    phone_number: '(650) 365-4333',
    latitude: 37.496708007668396,
    longitude: -122.13478163186996,
  },
  {
    name: 'Caliber - San Carlos',
    address_line_1: '794 Industrial Rd',
    address_line_2: 'San Carlos, CA 94070',
    phone_number: '(650) 802-9900',
    latitude: 37.50752016658041,
    longitude: -122.25232653451164,
  },
  {
    name: 'Carstar Chilton - San Carlos',
    address_line_1: '361 Quarry Rd',
    address_line_2: 'San Carlos, CA 94070',
    phone_number: '(650) 591-7700',
    latitude: 37.51801693231998,
    longitude: -122.26549699091905,
  },
];
