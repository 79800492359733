import React from 'react';
import './PulsatingDot.css';

export default function PulsatingDot() {
  return (
    <div style={{ transform: 'scale(0.5)' }}>
      <div className="PulsatingDot" />
    </div>
  );
}
