import classNames from 'classnames';
import React, { useState } from 'react';
import Toggle from '../../../components/Toggle';
import Transition from './Transition';

interface ManagePolicyholdersModalProps {
  show: boolean;
  onClose: () => void;
}

const ManagePolicyholdersModal: React.FC<ManagePolicyholdersModalProps> = ({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) => {
  const [policySync, setPolicySync] = useState(true);
  const [claimSync, setClaimSync] = useState(true);

  return (
    <div
      className={
        show
          ? 'fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-20'
          : 'hidden'
      }
    >
      <Transition
        show={show}
        appear={false}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
      </Transition>
      <Transition
        show={show}
        appear={false}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-md sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div className="text-left">
              <h3 className="text-lg text-center leading-6 font-medium text-gray-900">
                Monitored Policies
              </h3>
            </div>
          </div>
          <ul className="mt-2 divide-y divide-gray-200">
            <li className="py-4 flex items-center justify-between">
              <div className="flex flex-col">
                <p className="text-sm font-medium text-gray-900">Policy Sync</p>
                <p className="text-sm text-gray-500">
                  Automatically syncronize policyholder information from{' '}
                  <strong>Sample_PolicyCenter_1</strong>.
                </p>
              </div>
              <div className="ml-4" style={{ transform: 'scale(0.5)' }}>
                <Toggle
                  isDisabled={!policySync}
                  onToggle={() => setPolicySync(!policySync)}
                />
              </div>
            </li>
            <li className="py-4 flex items-center justify-between">
              <div className="flex flex-col">
                <p className="text-sm font-medium text-gray-900">Claim Sync</p>
                <p className="text-sm text-gray-500">
                  Automatically syncronize policyholder information from{' '}
                  <strong>Sample_ClaimCenter_1</strong>.
                </p>
              </div>
              <div className="ml-4" style={{ transform: 'scale(0.5)' }}>
                <Toggle
                  isDisabled={!claimSync}
                  onToggle={() => setClaimSync(!claimSync)}
                />
              </div>
            </li>
            <li className="py-4 flex items-center justify-between">
              <div
                className={classNames(
                  'flex flex-col',
                  policySync && 'opacity-25 pointer-events-none select-none',
                )}
              >
                <p className="text-sm font-medium text-gray-900">
                  Manual Policy Import
                </p>
                <p className="text-sm text-gray-500">
                  Manually import policyholder details via CSV.{' '}
                  <strong>Replaces existing policyholder database.</strong>{' '}
                  In-progress experiences will not be affected.
                </p>
                <label
                  htmlFor="file_upload"
                  className="border-2 border-gray-300 border-dashed rounded text-center py-3 font-medium bg-gray-200 text-gray-500 mt-2 w-full text-sm cursor-pointer"
                >
                  Drag and drop, or click to select
                  <input type="file" id="file_upload" className="hidden" />
                </label>
              </div>
            </li>
          </ul>
          <div className="mt-5 sm:mt-6">
            <span className="flex gap-4">
              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gray-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                onClick={() => onClose()}
              >
                Discard
              </button>
              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                onClick={() => onClose()}
              >
                Save changes
              </button>
            </span>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default ManagePolicyholdersModal;
