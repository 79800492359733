import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Sparklines, SparklinesLine } from 'react-sparklines';

import Toggle from '../../../components/Toggle';
import DescriptionPill from '../DescriptionPill';
import ManagePolicyholdersModal from '../ManagePolicyholdersModal';

interface SidebarProps {}

const Sidebar: React.FC<SidebarProps> = ({}) => {
  const [enabled, setEnabled] = useState(true);
  const [showPolicyManagement, setShowPolicyManagement] = useState(false);

  return (
    <>
      <ManagePolicyholdersModal
        show={showPolicyManagement}
        onClose={() => setShowPolicyManagement(false)}
      />
      <DescriptionPill>
        <div className="flex flex-col items-center justify-center w-full mt-2">
          <Toggle
            isDisabled={!enabled}
            onToggle={() => {
              if (
                !enabled ||
                window.confirm(
                  `Are you sure? No further events will be triggered until the engine is re-enabled.`,
                )
              ) {
                setEnabled(!enabled);
              }
            }}
          />
          <div className="mt-2 font-medium">Assured Pre-CAT Engine</div>
          <div className="mt-2 text-xs leading-4 text-gray-400">
            {enabled
              ? `The Assured Pre-CAT Engine is currently engaged and automatically
            monitoring for catastrophic events impacting your policyholders.`
              : `The Assured Pre-CAT Engine is currently disabled. Enable it above to begin automatically monitoring for catastrophic events.`}
          </div>
        </div>
      </DescriptionPill>
      <DescriptionPill
        label="Monitored policies"
        footer={
          <span
            onClick={e => {
              e.preventDefault();
              setShowPolicyManagement(true);
            }}
          >
            Manage policies
          </span>
        }
      >
        85,101,230
      </DescriptionPill>
      <DescriptionPill label="Ongoing events" detail="1,306,218 users affected">
        2
      </DescriptionPill>
      <DescriptionPill label="Users engaged (last 24h)">
        1,427,692
        <div className="mt-2">
          <Sparklines
            data={[5, 10, 5, 20, 8, 15, 12, 21, 33, 21, 42, 21, 18, 16]}
            height={60}
          >
            <SparklinesLine color="blue" />
          </Sparklines>
        </div>
      </DescriptionPill>
      <DescriptionPill label="Estimated total exposure" detail="± $425M">
        $1.9B
      </DescriptionPill>
    </>
  );
};

export default Sidebar;
