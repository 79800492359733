import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import getFlowStep from './utils';
import DraggableOption from './DraggableOption';
import Toggle from '../../../components/Toggle';

import { CatStep, FlowStep, OnDeleteClickArgs } from '../../types';

import './CatBuilder.css';
import MessagingModal from '../MessagingModal';
interface CatToggleCardProps {
  /** Step in the catastrophe */
  catStep: CatStep;
  /** idx of the cat step, primarily used for deleting CatSteps */
  catIdx: number;
  /** List of all possible flow options */
  flowSteps: FlowStep[];
  /** Function responsible for removing a CatStep from the Cat State */
  onDeleteClick: ({ catIdx, stepIdx }: OnDeleteClickArgs) => void;
  /** Callback that is used when toggle is switched */
  onToggle: () => void;
}

const CatBuilder: React.FC<CatToggleCardProps> = ({
  catStep,
  catIdx,
  children,
  flowSteps,
  onDeleteClick,
  onToggle,
}) => {
  const { disabled, steps, icon, last_seen, total_events } = catStep;
  const [showMessaging, setShowMessaging] = useState(false);

  return (
    <>
      <MessagingModal
        catStep={catStep}
        show={showMessaging}
        onClose={() => setShowMessaging(false)}
      />
      <div className="flex w-full bg-white rounded overflow-hidden mb-4">
        <div className="flex-shrink-0 p-6 w-56 flex flex-col items-center justify-center">
          <div className="text-5xl mb-1">{icon}</div>
          <div className="text-gray-700 font-bold text-xl">{catStep.label}</div>
          <div className="mb-3 text-gray-500 text-sm text-center">
            <div>
              {!last_seen ? `Never seen` : `Last seen ${last_seen} ago`}
            </div>
            <div>{total_events || 0} total events to date</div>
          </div>
          <Toggle onToggle={onToggle} isDisabled={disabled} />
          <button
            type="button"
            className="mt-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setShowMessaging(true)}
          >
            Review messaging
          </button>
        </div>
        {!disabled ? (
          <Droppable droppableId={`catTypes-${catIdx}`} direction="horizontal">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  minHeight: 180,
                }}
                className="flex-1 flex items-center flex-nowrap p-4 overflow-x-auto overflow-y-hidden CatBuilder-Droppable bg-gray-300"
              >
                {steps.map((step: { id: string }, idx: number) => {
                  const flowStep = getFlowStep({
                    stepId: step.id,
                    flowSteps: flowSteps,
                  });
                  if (flowStep) {
                    const { label, subText } = flowStep;
                    return (
                      <DraggableOption
                        key={`${label}-${idx}`}
                        label={label}
                        subText={subText}
                        stepKey={`${catStep.label}-${label}-${idx}`}
                        idx={idx}
                        onDeleteClick={() =>
                          onDeleteClick({ catIdx, stepIdx: idx })
                        }
                      />
                    );
                  }
                  return null;
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ) : (
          <div className="flex-1 flex p-4 bg-gray-300 items-center justify-center font-bold text-gray-500">
            Not currently enabled
          </div>
        )}
      </div>
    </>
  );
};

export default CatBuilder;
