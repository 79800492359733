import React, { useState } from 'react';
import { CatStep } from '../../types';
import Transition from './Transition';

interface SuccessModalProps {
  show: boolean;
  onClose: () => void;
  catStep: CatStep;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  show,
  onClose,
  catStep,
}) => {
  const sections = [
    { title: 'Before event begins', items: catStep.sample_messages.before },
    { title: 'During the event', items: catStep.sample_messages.during },
    { title: 'After the event', items: catStep.sample_messages.after },
  ];
  const [offset, setOffset] = useState(0);

  return (
    <div
      className={
        show
          ? 'fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-20'
          : 'hidden'
      }
    >
      <Transition
        show={show}
        appear={false}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
      </Transition>
      <Transition
        show={show}
        appear={false}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div className="">
              <h3 className="text-center text-lg leading-6 font-medium text-gray-900">
                {catStep.label}
              </h3>
              <div className="mt-2 flex items-center">
                <p className="flex-1 text-sm leading-5 text-gray-500">
                  The following is a sample set of messages generated by the
                  Assured Pre-CAT Engine.
                </p>
                <button
                  type="button"
                  className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() =>
                    setTimeout(
                      () => setOffset(offset + 1),
                      200 + Math.random() * 300,
                    )
                  }
                >
                  <svg
                    className="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          {sections.map(s => {
            const allItems = s.items;
            const slice = (offset * 2) % allItems.length;
            const items = allItems.slice(slice, slice + 2);

            return (
              <div className="mt-5">
                <div className="font-medium text-base mb-2">{s.title}</div>
                <div className="grid grid-cols-1 gap-2">
                  {items.map(item => (
                    <div className="relative rounded-lg border border-gray-300 bg-white px-3 py-2 shadow-sm flex items-center">
                      <div className="flex-1 min-w-0">
                        <p className="text-xs font-medium text-gray-600">
                          {item}
                        </p>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        className="w-3 h-3 ml-2 text-gray-400 hover:text-red-500 cursor-pointer"
                        fill="currentColor"
                        onClick={() =>
                          window.alert(`This message has been flagged.`)
                        }
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}

          <div className="mt-5 sm:mt-6">
            <span className="flex w-full rounded-md shadow-sm">
              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                onClick={() => onClose()}
              >
                Exit preview
              </button>
            </span>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default SuccessModal;
