import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import './DraggableOption.css';
interface DraggableOptionProps {
  /** Main text of the Option component */
  label: string;
  /** Sub text of the Option component */
  subText: string;
  /** ID for Draggable dnd component */
  stepKey: string;
  /** Index of the step in the Cat State Array. */
  idx: number;
  /** Function responsible for removing a CatStep from the Cat State */
  onDeleteClick: () => void;
}

const DraggableOption: React.FC<DraggableOptionProps> = ({
  label,
  subText,
  stepKey,
  idx,
  onDeleteClick,
}) => {
  return (
    <Draggable key={stepKey} draggableId={stepKey} index={idx}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          className="bg-gray-50 rounded shadow p-4 flex-shrink-0 w-64 mr-3 relative"
          style={{
            ...provided.draggableProps.style,
            height: 130,
          }}
        >
          <div
            className="absolute cursor-pointer top-2 right-2"
            onClick={onDeleteClick}
          >
            ✕
          </div>
          <div className="text-base text-gray-700 font-bold mr-3">{label}</div>
          <div className="mt-1 text-sm text-medium text-gray-500 DraggableOption-subText">
            {subText}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableOption;
