const CAT_TYPE = 'catTypes';
const FLOW_STEPS_TYPE = 'flowSteps';

const sample_messages = {
  before: [
    'Hi [name], [carrier] here. Looks like there’s a winter storm headed towards your home in [town], estimated arrival in [time]. There are steps you can take to reduce potential damage to your home. Tap below to learn more. [link]',
    'Hi [name], [carrier] here. Looks like there’s a winter storm headed towards your home in [town], estimated arrival in [time]. There are steps you can take to reduce potential damage to your home. Click the link below to learn more. [link]',
    'Hi [name], [carrier] here. Looks like there’s a storm headed towards your home in [town], arriving in [time]. There are steps you can take to reduce potential damage to your home. Click here to learn more. [link]',
    'Hi [name], [carrier] here. Looks like there’s a storm headed towards your home in [town], arriving in [time]. There are 5 steps you can take to reduce potential damage to your home. Click here to learn more. [link] ',
    'Hi [name], [carrier] here. Looks like there’s a storm headed towards your home in [town], arriving in [time]. There are five steps you can take to reduce potential damage to your home. Click here to learn more. [link]',
    'Hi [name], [carrier] here. Looks like there’s a severe storm headed towards your home in [town], arriving in [time]. There are five steps you can take to reduce potential damage to your property. Click here to learn more. [link]',
    'Hi [name], [carrier] here. Looks like there’s a severe storm arriving in [time]. There are five steps you can take to reduce potential damage to your property. Click here to learn more. [link]',
    "Hi [name], it's [carrier]. Looks like there’s a severe storm arriving in [time]. There are five steps you can take to reduce potential damage to your property. Click here to learn more. [link]",
    "Hi [name], it's [carrier]. Looks like there’s a severe storm arriving in [time]. There are a handful of steps you can take to reduce potential damage to your property. Click here to learn more. [link]",
    "Hi [name], it's [carrier]. Looks like there’s a severe storm arriving in [time]. There are a handful of steps you can take to reduce potential damage to your property. Tap below to learn more. [link]",
  ],
  during: [
    "Hi [name], how's it going in [town]? Good news, the worst of the storm appears to be over. Tap below for some weather updates. [link]",
    "Hi [name], how's it going in [town]? Good news, the worst of the storm appears to be over. Click the link below for some weather updates. [link]",
    "Hi [name], we're glad the worst of the storm is over. Click the link below for some weather updates. [link]",
    'Hi [name], looks like the worst of the storm has yet to come. Click the link below for some weather updates. [link]',
    'Hi [name], looks like the worst of the storm has yet to come. Tap below for some weather updates. [link]',
    'Hi [name], looks like 3 more inches of rain is expected before midnight. Click the link below for some weather updates. [link]',
    'Hi [name], looks like 3 more inches of rain is expected before midnight. Tap below for some weather updates. [link]',
    'Hi [name], looks like 3 more inches of snow is expected before midnight, along with high winds. Tap below for some weather updates. [link]',
    'Hi [name], looks like 3 inches of snow is expected before midnight, along with light winds. Tap below for some weather updates. [link]',
    "Hi [name], looks like it's going to keep hailing for another 35 minutes. Tap below for some weather updates.",
  ],
  after: [
    'Hi [name], we hope you and your property are doing OK after that snowstorm. Tap below if you need to file a claim. [link]',
    'Hi [name], do you need to file a claim after the snowstorm? Tap below to let us know [link]',
    'Hi [name], was any of your property damaged in the storm? Tap below to let us know.  [link]',
    'Hi [name], was any of your property damaged in the storm? Click the link below to let us know.  [link]',
    "Hi [name], we want to know you're OK. Tap below to let us know.  [link]",
    'Hi [name], [carrier] here. Do you need to file a claim? If so, tap below.  [link]',
    'Hi [name], [carrier] here. Do you need to file a claim? Either way, tap below to let us know.  [link]',
    'Hi [name]. Do you need to file a claim? Either way, tap below to let us know.  [link]',
    "Hi [name], we hope your home in [town] didn't get damaged. But just in case, please let us now you're alright by tapping below. [link]",
    "Hi [name], we hope your home in [town] didn't get damaged. If you need to file a claim, please just tap the link below. [link]",
  ],
};

const INITIAL_STATE = {
  catTypes: [
    {
      label: 'Winter storm',
      icon: '❄️',
      last_seen: '3h',
      total_events: 3,
      disabled: false,
      steps: [
        {
          id: 'gather-supplies',
        },
        {
          id: 'get-emergency-water',
        },
        {
          id: 'collect-clothing',
        },
        {
          id: 'drive-car-in-winter',
        },
        {
          id: 'clean-gutters',
        },
        {
          id: 'insultate-windows',
        },
        {
          id: 'prepare-power-sources',
        },
        {
          id: 'prevent-frozen-pipes',
        },
        {
          id: 'ready-vehicle',
        },
        {
          id: 'remove-ice-dams',
        },
        {
          id: 'warm-your-home',
        },
        {
          id: 'prepare-emergency-supplies',
        },
        {
          id: 'prepare-for-winter-storm',
        },
      ],
      sample_messages,
    },
    {
      label: 'Hurricane',
      icon: '🌀',
      last_seen: '72d',
      total_events: 14,
      disabled: false,
      //TODO fill these out
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
        {
          id: 'insultate-windows',
        },
        {
          id: 'get-emergency-water',
        },
        {
          id: 'prepare-power-sources',
        },
      ],
      sample_messages,
    },
    {
      label: 'Wild fire',
      icon: '🔥',
      last_seen: '45d',
      total_events: 4,
      disabled: true,
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
    {
      label: 'Tornado',
      icon: '🌪️',
      last_seen: '72d',
      total_events: 6,
      disabled: true,
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
    {
      label: 'Hailstorm',
      icon: '🧊',
      last_seen: '12d',
      total_events: 106,
      disabled: true,
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
    {
      label: 'Flood',
      icon: '🌊',
      last_seen: '874d',
      total_events: 65,
      disabled: true,
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
    {
      label: 'Windstorm',
      icon: '💨',
      last_seen: '45d',
      total_events: 108,
      disabled: true,
      //TODO fill these out
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
    {
      label: 'Earthquake',
      icon: '🌎',
      last_seen: '1065d',
      total_events: 108,
      disabled: true,
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
    {
      label: 'Lightning storm',
      icon: '🌩️',
      last_seen: '3d',
      total_events: 456,
      disabled: true,
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
    {
      label: 'Blizzard',
      icon: '🌨️',
      last_seen: '3d',
      total_events: 1456,
      disabled: true,
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
    {
      label: 'Dust storm',
      icon: '🏜️',
      last_seen: '3d',
      total_events: 84,
      disabled: true,
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
    {
      label: 'Ice storm',
      icon: '🏜️',
      last_seen: '3d',
      total_events: 84,
      disabled: true,
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
    {
      label: 'Heat wave',
      icon: '🏜️',
      last_seen: '3d',
      total_events: 84,
      disabled: true,
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
    {
      label: 'Atmospheric river',
      icon: '🏜️',
      last_seen: '3d',
      total_events: 84,
      disabled: true,
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
    {
      label: 'Land slides',
      icon: '🏜️',
      last_seen: '3d',
      total_events: 84,
      disabled: true,
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
    {
      label: 'Tsunami',
      icon: '🏜️',
      last_seen: '3d',
      total_events: 84,
      disabled: true,
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
    {
      label: 'Volcanic eruption',
      icon: '🏜️',
      last_seen: '3d',
      total_events: 84,
      disabled: true,
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
    {
      label: 'Terrorist event',
      icon: '🏜️',
      last_seen: '3d',
      total_events: 84,
      disabled: true,
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
    {
      label: 'Nuclear disaster',
      icon: '🏜️',
      last_seen: '3d',
      total_events: 84,
      disabled: true,
      steps: [
        {
          id: 'store-vehicles',
        },
        {
          id: 'prepare-kit',
        },
      ],
      sample_messages,
    },
  ],
  flowSteps: [
    {
      id: 'gather-supplies',
      label: 'Gather supplies',
      subText:
        'Make sure you have everything you need for the coming weather. ',
    },
    {
      id: 'ready-vehicle',
      label: 'Ready your vehicle',
      subText: 'Get gas, install winter tires, and more.',
    },
    {
      id: 'warm-your-home',
      label: 'Warm your home',
      subText:
        'Take these steps to keep your home safe and warm during the storm. ',
    },
    {
      id: 'prevent-frozen-pipes',
      label: 'Prevent frozen pipes',
      subText:
        'Frozen pipes can be expensive to repair. Fortunately, this damage is preventable. ',
    },
    {
      id: 'prepare-power-sources',
      label: 'Prepare power sources',
      subText:
        'Have backup electricity sources ready in case of a power outage. ',
    },
    {
      id: 'insulate-windows',
      label: 'Collect clothing',
      subText: 'Insulating your windows can help during an outage. ',
    },
    {
      id: 'remove-ice-dams',
      label: 'Remove Ice Dams',
      subText: 'Ice dams can be deadly. Learn how to safely remove them. ',
    },
    {
      id: 'get-emergency-water',
      label: 'Get emergency water from a water heater',
      subText: 'Gather water in case of an emergency. ',
    },
    {
      id: 'prepare-kit',
      label: 'Prepare Emergency Kit',
      subText:
        'Make sure the user has a food, and necessary preperations for the emergency',
    },
    {
      id: 'turn-off-water-heater',
      label: 'Turn off water heater',
      subText:
        'Sends step by step instructions to mitigate damage to the Water heater.',
    },
    {
      id: 'insulate-home',
      label: 'Insulate your home',
      subText:
        'Sends step by step instructions so Users are prepared for the cold',
    },
    {
      id: 'turn-off-gas-lines',
      label: 'Turn off gas lines',
      subText:
        'Sends step by step instructions so Users can turn off the gas lines',
    },
    {
      id: 'prune-trees',
      label: 'Prune trees',
      subText: 'Help user mitigate damage to the home by removing some trees.',
    },
    {
      id: 'secure-furniture',
      label: 'Secure or move outdoor furniture',
      subText:
        'Reduce the chances of home damages by having the user secure objects',
    },
    {
      id: 'board-windows',
      label: 'Board up windows',
      subText:
        'Reduce the chances of the claimant being harmed by flying objects',
    },
    {
      id: 'store-vehicles',
      label: 'Store vehicles in garage or storage building',
      subText:
        'Instruct the user to park their vehicle safely, mitigating damages to the car',
    },
  ],
};

export { CAT_TYPE, FLOW_STEPS_TYPE, INITIAL_STATE };
