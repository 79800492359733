import React from 'react';
import PulsatingDot from 'src/components/Cat/components/PulsatingDot';

interface DescriptionPillProps {
  /** label for the Pill */
  label?: string;
  /** Whether to show a view more link */
  viewMore?: boolean;
  /** Main content of the Pill */
  children: React.ReactNode;
  /** Detail text */
  detail?: React.ReactNode;
  /** Footer content */
  footer?: React.ReactNode;
}

const DescriptionPill: React.FC<DescriptionPillProps> = ({
  label,
  viewMore,
  children,
  detail,
  footer,
}) => {
  return (
    <div className="bg-white relative overflow-hidden shadow rounded-lg">
      <div className="absolute top-5 right-5"></div>
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-shrink-0"></div>
          <div className="w-0 flex-1">
            <dl>
              {label && (
                <dt className="flex items-center text-sm font-medium text-gray-500">
                  <span className="flex-1">{label}</span>
                  <span style={{ paddingTop: 2 }}>
                    <PulsatingDot />
                  </span>
                </dt>
              )}
              <dd>
                <div className="text-lg font-medium text-gray-900">
                  {children}
                  {detail ? (
                    <span className="ml-2 text-sm text-gray-400">{detail}</span>
                  ) : null}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      {(viewMore || footer) && (
        <div className="bg-gray-100 px-5 py-3">
          <div className="text-sm">
            <a
              href="#"
              className="font-medium text-gray-700 hover:text-gray-800"
            >
              {footer ? footer : 'View details'}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default DescriptionPill;
