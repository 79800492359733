export const catastrophes = [
  {
    title: 'Winter Storm Uri',
    identifier: 'CAT-S2SJ29',
    stage: 'during',
    type: 'Winter storm',
    severity: 'high',
    description: 'Large-scale cold front descending from the north.',

    monitored_policies: 7335726,
    users_engaged: 1422483,
    total_exposure: `$1.8B`,

    check_ins_sent: 0,
    fnols_submitted: 0,
    total_loss: `—`,

    events: [
      {
        label: 'Next up: Dispatch "After" FNOL flow',
        time: '',
        next: true,
      },
      {
        label: 'Estimated total exposure increased to $1.8B',
        time: '1h ago',
      },
      {
        label: '"During" flow dispatched to 1,422,483 affected',
        time: '1d 7h ago',
      },
      {
        label: 'Estimated total exposure increased to $1.1B',
        time: '1d 8h ago',
      },
      {
        label: 'Initial impact',
        time: '1d 9h ago',
      },
      {
        label: 'Pre-CAT flow dispatched to 949,792 affected',
        time: '2d 5h ago',
      },
      {
        label: 'Pre-CAT flow dispatched to 472,691 affected',
        time: '2d 8h ago',
      },
      {
        label: 'Storm identified',
        time: '3d ago',
      },
    ],
  },
  {
    title: 'Hailstorm near Salem, OR',
    identifier: 'CAT-A12SN21',
    stage: 'before',
    type: 'Hailstorm',
    severity: 'low',
    description: 'Minor hailstorm caused by occluded front.',

    monitored_policies: 6762,
    users_engaged: 5209,
    total_exposure: `$12.6M`,

    check_ins_sent: 0,
    fnols_submitted: 0,
    total_loss: `$21.3M`,

    events: [
      {
        label: 'Next up: Dispatch "During" flow',
        time: '',
        next: true,
      },
      {
        label: 'Pre-CAT flow dispatched to 5,209 affected',
        time: '14h ago',
      },
      {
        label: 'Storm identified',
        time: '17h ago',
      },
    ],
  },
  {
    title: 'Tornado near Topeka, KS',
    identifier: 'CAT-SOI27SJ',
    stage: 'after',
    type: 'Tornado',
    severity: 'high',
    description: 'A complex of tornados touched down in outer-Topeka.',

    monitored_policies: 9073,
    users_engaged: 1136,
    total_exposure: `$9M`,

    check_ins_sent: 921,
    fnols_submitted: 159,
    total_loss: `$6.2M`,

    events: [
      {
        label: 'Estimated total loss increased to $6.2M',
        time: '6d 11h ago',
      },
      {
        label: 'Check-ins dispatched to 921 affected',
        time: '7d 5h ago',
      },
      {
        label: 'Estimated total exposure increased to $9.0M',
        time: '7d 8h ago',
      },
      {
        label: 'Initial impact',
        time: '1d 9h ago',
      },
      {
        label: 'Pre-CAT flow dispatched to 630 affected',
        time: '2d 5h ago',
      },
      {
        label: 'Pre-CAT flow dispatched to 506 affected',
        time: '2d 8h ago',
      },
      {
        label: 'Storm identified',
        time: '3d ago',
      },
    ],
  },
  {
    title: 'Fire near Santa Rosa, CA',
    identifier: 'CAT-JK38D02',
    stage: 'after',
    type: 'Wildfire',
    severity: 'medium',
    description: 'Approximately 18,000 acres burned after lightning strike.',

    monitored_policies: 48384,
    users_engaged: 46507,
    total_exposure: `$165M`,

    check_ins_sent: 35930,
    fnols_submitted: 4834,
    total_loss: `$111M`,

    events: [
      {
        label: 'Estimated total loss increased to $111M',
        time: '21d 18h ago',
      },
      {
        label: 'Check-ins dispatched to 35,930 affected',
        time: '20d 9h ago',
      },
      {
        label: 'Estimated total exposure increased to $165M',
        time: '7d 8h ago',
      },
      {
        label: 'Initial impact',
        time: '1d 9h ago',
      },
      {
        label: 'Pre-CAT flow dispatched to 27,300 affected',
        time: '2d 5h ago',
      },
      {
        label: 'Pre-CAT flow dispatched to 19,207 affected',
        time: '2d 8h ago',
      },
      {
        label: 'Storm identified',
        time: '3d ago',
      },
    ],
  },
];

export const txBounds = [
  {
    lat: 29.729188566296976,
    lng: -104.06681299209596,
  },
  {
    lat: 29.16581101060806,
    lng: -103.29378962516786,
  },
  {
    lat: 29.77347501558793,
    lng: -102.80408263206482,
  },
  {
    lat: 30.020894408912504,
    lng: -101.47101938724519,
  },
  {
    lat: 29.703864921543605,
    lng: -100.94937264919282,
  },
  {
    lat: 28.96078921084095,
    lng: -100.42520999908449,
  },
  {
    lat: 28.52474774557018,
    lng: -100.108859539032,
  },
  {
    lat: 29.093943087617937,
    lng: -97.92528390884401,
  },
  {
    lat: 28.505095253774957,
    lng: -97.41786360740663,
  },
  {
    lat: 28.621663819318073,
    lng: -96.36559545993806,
  },
  {
    lat: 29.40865546324703,
    lng: -94.89364743232727,
  },
  {
    lat: 29.919350204614087,
    lng: -93.97089779376985,
  },
  {
    lat: 29.8309799734109,
    lng: -92.16345906257631,
  },
  {
    lat: 32.197758588656676,
    lng: -91.92276835441591,
  },
  {
    lat: 32.96024595000238,
    lng: -90.71962594985962,
  },
  {
    lat: 34.550644954088,
    lng: -90.55955171585084,
  },
  {
    lat: 36.13584284095472,
    lng: -92.07108378410341,
  },
  {
    lat: 36.413370784593035,
    lng: -95.51000833511354,
  },
  {
    lat: 34.70703249690159,
    lng: -98.36427569389345,
  },
  {
    lat: 34.43592295900212,
    lng: -99.65280890464784,
  },
  {
    lat: 33.45497966748919,
    lng: -101.83489322662355,
  },
  {
    lat: 33.083352542139544,
    lng: -103.31847667694092,
  },
  {
    lat: 33.45516316842151,
    lng: -105.22374629974367,
  },
  {
    lat: 31.719401746155874,
    lng: -104.83232617378235,
  },
  {
    lat: 31.100195385468503,
    lng: -104.65057969093324,
  },
];

export const pnwBounds = [
  {
    lat: 46.35014264951712,
    lng: -123.76786887645723,
  },
  {
    lat: 45.150739258862075,
    lng: -123.82948994636537,
  },
  {
    lat: 44.33788379595202,
    lng: -123.98681223392487,
  },
  {
    lat: 44.45835988799718,
    lng: -122.92629897594453,
  },
  {
    lat: 44.30659884506572,
    lng: -121.66716277599336,
  },
  {
    lat: 45.15398611810687,
    lng: -121.85316860675813,
  },
  {
    lat: 45.80114930179738,
    lng: -122.30696082115175,
  },
  {
    lat: 45.93475231621419,
    lng: -123.0377233028412,
  },
];

export const napaBounds = [
  { lat: 38.3395019462746, lng: -122.57217111365722 },
  { lat: 38.38042167460681, lng: -122.72863027503618 },
  { lat: 38.46434231629165, lng: -122.81097720207775 },
  { lat: 38.57393751557594, lng: -122.72863027503618 },
  { lat: 38.59540719940386, lng: -122.6407935528585 },
  { lat: 38.47939467327645, lng: -122.51727316229616 },
  { lat: 38.436379603, lng: -122.51727316229616 },
];

export const tpkBounds = [
  { lat: 38.86965182408357, lng: -95.55515204903516 },
  { lat: 38.886757140695906, lng: -95.81866221556817 },
  { lat: 39.185433170302126, lng: -95.96139688910688 },
  { lat: 39.13645165015623, lng: -95.66123954124001 },
  { lat: 39.279041894366785, lng: -95.52770307335467 },
  { lat: 39.04905220645355, lng: -95.56064184417127 },
  { lat: 38.91240739487225, lng: -95.53319286849077 },
];
