import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { FlowStep } from '../../types';
import FlowTodoStep from './FlowTodoStep';

interface FlowStepBuilder {
  flowSteps: FlowStep[];
}

const FlowStepBuilder: React.FC<FlowStepBuilder> = ({ flowSteps }) => {
  const [flowStepFilter, setFlowStepFilter] = useState<string>('');
  const filteredSteps = flowSteps.filter(step => {
    return (
      !flowStepFilter ||
      step.label.toLowerCase().includes(flowStepFilter) ||
      step.subText.toLowerCase().includes(flowStepFilter)
    );
  });
  const totalSteps = 392;

  return (
    <div
      className="flex flex-col bg-gray-200 rounded px-4 pt-4 mr-4"
      style={{
        height: 'calc(100vh - 150px)',
        maxWidth: 288,
        minWidth: 288,
      }}
    >
      <div className="px-4 py-4 bg-gray-300 text-gray-600 rounded-t font-bold -mx-4 -mt-4">
        <div className="flex items-baseline">
          <span className="flex-1">To-Do Library</span>
          <span className="text-xs text-gray-400">
            {filteredSteps.length === flowSteps.length
              ? totalSteps
              : filteredSteps.length}{' '}
            of {totalSteps}
          </span>
        </div>
        <div className="mt-2 relative rounded-md shadow-sm">
          <input
            className="form-input block w-full sm:text-sm sm:leading-5"
            placeholder="Find a to-do..."
            value={flowStepFilter}
            onChange={e => setFlowStepFilter(e.target.value.toLowerCase())}
          />
        </div>
      </div>
      <Droppable droppableId="flowSteps">
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="overflow-y-scroll overflow-x-hidden"
          >
            <div className="flex-1 -mx-1 px-1 pt-4">
              {filteredSteps.map((step, idx) => (
                <FlowTodoStep
                  stepKey={`${step.label}-${idx}`}
                  label={step.label}
                  subText={step.subText}
                  idx={idx}
                  key={idx}
                />
              ))}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default FlowStepBuilder;
