import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import PulsatingDot from '../../../components/PulsatingDot';
import { catastrophes } from '../../consts';

import sampleCatSalem from '../../../../../images/catastrophe/sample_cat_salem.png';
import sampleCatUri from '../../../../../images/catastrophe/sample_cat_uri.png';
import sampleCatTopeka from '../../../../../images/catastrophe/sample_cat_topeka.png';
import sampleCatNapa from '../../../../../images/catastrophe/sample_cat_napa.png';

interface CardProps {
  catastrophe: typeof catastrophes[number];
}

const Item: React.FC<{ label: string; noPulsatingDot?: boolean }> = ({
  label,
  noPulsatingDot,
  children,
}) => {
  return (
    <dl>
      {label && (
        <dt className="flex items-center text-sm font-medium text-gray-500">
          <span className="flex-1">{label}</span>
          {!noPulsatingDot && (
            <span style={{ paddingTop: 2 }}>
              <PulsatingDot />
            </span>
          )}
        </dt>
      )}
      <dd>
        <div className="text-lg font-medium text-gray-900">{children}</div>
      </dd>
    </dl>
  );
};

const Timeline: React.FC<{
  events: { label: string; time: string; next?: boolean }[];
}> = ({ events }) => {
  return (
    <div className="flow-root overflow-auto" style={{ maxHeight: 200 }}>
      <ul>
        {events.map(({ label, time, next }, i) => {
          const baseLabel = label.toLowerCase();
          const iconType = baseLabel.includes('dispatch')
            ? 'dispatch'
            : baseLabel.includes('identified')
            ? 'identify'
            : 'increase';

          return (
            <li>
              <div
                className={classNames(
                  'relative',
                  i !== events.length - 1 ? 'pb-8' : 'pb-4',
                  i === 0 && 'pt-4',
                )}
              >
                {i !== events.length - 1 && (
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                )}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={classNames(
                        'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                        next ? 'bg-blue-500' : 'bg-gray-400',
                      )}
                    >
                      {iconType === 'dispatch' ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="text-white h-4 w-4"
                        >
                          <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                        </svg>
                      ) : iconType === 'identify' ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="text-white h-4 w-4"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : iconType === 'increase' ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className="text-white h-4 w-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                          />
                        </svg>
                      ) : null}
                    </span>
                  </div>
                  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                    <div>
                      <p
                        className={classNames(
                          'text-xs',
                          next ? 'text-blue-400 font-bold' : 'text-gray-500',
                        )}
                      >
                        {label}
                      </p>
                    </div>
                    {time ? (
                      <div className="text-right text-xs whitespace-nowrap text-gray-500 whitespace-no-wrap">
                        {time}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Card: React.FC<CardProps> = ({ catastrophe }) => {
  const stage = catastrophe.stage;

  return (
    <div
      className={classNames(
        'w-1/4 flex-shrink-0 shadow p-4 rounded-md flex flex-col gap-4',
        stage === 'after' ? 'bg-gray-200' : 'bg-white',
      )}
    >
      <div className="font-bold text-center text-md">
        {catastrophe.title}
        <div className="text-center text-xs font-normal font-mono">
          {catastrophe.identifier}
        </div>
      </div>
      <span className="-mt-1 relative flex items-center justify-center text-center">
        <span
          className={classNames(
            'relative px-3 text-xs font-medium',
            stage === 'before' ? 'text-blue-500' : 'text-gray-400',
          )}
        >
          Before
        </span>
        <span
          className={classNames(
            '-ml-px relative px-3 text-xs font-medium',
            stage === 'during' ? 'text-blue-500' : 'text-gray-400',
          )}
        >
          During
        </span>
        <span
          className={classNames(
            '-ml-px relative px-3 text-xs font-medium',
            stage === 'after' ? 'text-blue-500' : 'text-gray-400',
          )}
        >
          After
        </span>
      </span>
      <div className="rounded bg-gray-400 h-36 w-full overflow-hidden">
        <img
          className="w-full h-full object-cover"
          src={
            catastrophe.title === 'Winter Storm Uri'
              ? sampleCatUri
              : catastrophe.title === 'Hailstorm near Salem, OR'
              ? sampleCatSalem
              : catastrophe.title === 'Tornado near Topeka, KS'
              ? sampleCatTopeka
              : sampleCatNapa
          }
        />
      </div>
      <div className="flex justify-center" style={{ fontSize: 10 }}>
        <span className="px-2 py-1 text-white bg-blue-500 rounded font-bold">
          {catastrophe.type}
        </span>
        {catastrophe.severity === 'high' ? (
          <span className="ml-2 px-2 py-1 text-white bg-red-500 rounded font-bold">
            High Severity
          </span>
        ) : catastrophe.severity === 'medium' ? (
          <span className="ml-2 px-2 py-1 text-white bg-orange-400 rounded font-bold">
            Medium Severity
          </span>
        ) : (
          <span className="ml-2 px-2 py-1 text-white bg-gray-400 rounded font-bold">
            Low Severity
          </span>
        )}
      </div>
      <div className="text-gray-500 text-sm">{catastrophe.description}</div>
      <div className="pt-4 border-t border-gray-300 flex flex-col gap-4 px-4 -mx-4">
        <Item noPulsatingDot label="Monitored policies">
          {catastrophe.monitored_policies.toLocaleString()}
        </Item>
        <Item
          noPulsatingDot={catastrophe.stage === 'after'}
          label="Users engaged"
        >
          {catastrophe.users_engaged.toLocaleString()}
        </Item>
        <Item
          noPulsatingDot={catastrophe.stage === 'after'}
          label="Estimated total exposure"
        >
          {catastrophe.total_exposure}
        </Item>
      </div>
      {catastrophe.check_ins_sent > 0 ? (
        <div className="pt-4 border-t border-gray-300 flex flex-col gap-4 px-4 -mx-4">
          <Item
            noPulsatingDot={catastrophe.stage === 'after'}
            label="Check-ins sent"
          >
            {catastrophe.check_ins_sent.toLocaleString()}
          </Item>
          <Item label="FNOLs submitted">
            {catastrophe.fnols_submitted.toLocaleString()} (
            {(
              (catastrophe.fnols_submitted /
                (catastrophe.check_ins_sent || 1)) *
              100
            ).toFixed(2)}
            %)
          </Item>
          <Item label="Estimated total loss">{catastrophe.total_loss}</Item>
        </div>
      ) : null}
      <div className="border-t border-gray-300 px-4 -mx-4 -mb-4">
        <Timeline events={catastrophe.events} />
      </div>
    </div>
  );
};

export default Card;
