const padNumber = (childCount: number) => {
  return Number(
    String(childCount).padEnd(
      String(childCount).length + 1,
      String(childCount * 7)[0] + 1,
    ),
  );
};

export { padNumber };
