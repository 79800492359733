import React, { useEffect, useState } from 'react';
import { DragDropContext, DraggableLocation, DropResult } from 'react-beautiful-dnd';

import CatBuilder from './components/CatBuilder';
import FlowStepBuilder from './components/FlowStepBuilder';
import SuccessModal from './components/SuccessModal';
import { CAT_TYPE, FLOW_STEPS_TYPE, INITIAL_STATE } from './consts';
import { CatStep, InitialState, OnDeleteClickArgs, onToggleArgs } from './types';

const CatFlow = () => {
  const [state, setState] = useState<InitialState>(INITIAL_STATE);
  const [shouldShowSuccess, setShowSuccess] = useState<boolean>(false);
  const [isPublishing, setPublishing] = useState<boolean>(false);

  const [dirty, setDirty] = useState(false);
  useEffect(() => {
    const id = setTimeout(() => setDirty(false), Math.random() * 400 + 300);
    return () => clearTimeout(id);
  }, [dirty]);

  const { flowSteps } = state;

  useEffect(() => {
    if (isPublishing) {
      const id = setTimeout(() => {
        setShowSuccess(true);
        setPublishing(false);
      }, Math.random() * 1000 + 2000);
      return () => clearTimeout(id);
    }
  }, [isPublishing]);

  const onDeleteClick = ({ catIdx, stepIdx }: OnDeleteClickArgs) => {
    const newCatTypeList = [...state.catTypes];
    const currentStep = newCatTypeList[catIdx];
    currentStep.steps.splice(stepIdx, 1);

    setState({ ...state, catTypes: newCatTypeList });
  };

  const onToggle = ({ idx }: onToggleArgs) => {
    const currentCatState = [...state.catTypes];
    const currentCatStep = currentCatState[idx];
    currentCatStep.disabled = !currentCatStep.disabled;
    currentCatState[idx] = currentCatStep;

    setState({ ...state, catTypes: currentCatState });
  };

  const reorder = ({
    list,
    startIndex,
    endIndex,
    catIdx,
  }: {
    list: any[];
    startIndex: number;
    endIndex: number;
    catIdx: number | null;
  }) => {
    let result = [...list];

    if (Number.isInteger(catIdx) && catIdx !== null) {
      result = [...list][catIdx].steps;
    }

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (
    source: any[],
    destination: any[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation,
  ) => {
    const { droppableId: sourceId, index: dropSourceIdx } = droppableSource;
    const {
      droppableId: destId,
      index: dropDestIdx,
      droppableId,
    } = droppableDestination;
    const sourceClone = [...source];
    const destClone = [...destination];

    if (sourceId === FLOW_STEPS_TYPE && destId.includes(CAT_TYPE)) {
      const catId = Number(droppableId.slice(-1));

      destClone[catId].steps.splice(dropDestIdx, 0, sourceClone[dropSourceIdx]);

      return {
        flowSteps: [...source],
        catTypes: [...destClone],
      };
    } else if (sourceId.includes(CAT_TYPE) && destId.includes(CAT_TYPE)) {
      const sourceCatIdx = Number(sourceId.slice(-1));
      const destCatIdx = Number(destId.slice(-1));

      const [removed] = destClone[sourceCatIdx].steps.splice(dropSourceIdx, 1);
      destClone[destCatIdx].steps.splice(dropDestIdx, 0, removed);

      return {
        ...state,
        catTypes: [...destClone],
      };
    }
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (
      !source ||
      !destination ||
      (!source.droppableId.includes(CAT_TYPE) &&
        source.droppableId !== FLOW_STEPS_TYPE) ||
      (!destination.droppableId.includes(CAT_TYPE) &&
        destination.droppableId !== FLOW_STEPS_TYPE)
    ) {
      return;
    }

    if (source.droppableId) {
      if (source.droppableId === destination.droppableId) {
        let dropId = source.droppableId;
        let catIdx = null;
        if (source.droppableId.includes(CAT_TYPE)) {
          // This slices the the state key off the droppable id, so we can access the catTypes off initialState.
          dropId = source.droppableId.slice(0, -2);
          // In order to get the idx in the catTypes step arr we include the index in the droppableId in the Drop component I.E catType-1, and slice it off the string
          catIdx = Number(source.droppableId.slice(-1));
        }

        const items = reorder({
          list: state[dropId],
          startIndex: source.index,
          endIndex: destination.index,
          catIdx,
        });

        setState({ ...state, [source.droppableId]: items });
      } else {
        if (destination.droppableId.includes(CAT_TYPE)) {
          let dropId = source.droppableId;
          if (source.droppableId.includes(CAT_TYPE)) {
            // In order to get the idx in the catTypes step arr we include the index in the droppableId in the Drop component I.E catType-1, and slice it off the string
            dropId = source.droppableId.slice(0, -2);
          }
          const result = move(
            state[dropId],
            state[CAT_TYPE],
            source,
            destination,
          );

          setState({
            ...(result as InitialState),
          });
        }
      }
    }

    setDirty(true);
  };

  return (
    <>
      <SuccessModal
        show={shouldShowSuccess}
        onClose={() => setShowSuccess(false)}
      />
      <main>
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div>
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="flex w-full">
                <FlowStepBuilder flowSteps={state.flowSteps} />
                <div
                  className="overflow-y-scroll flex-1 w-0 flex flex-col bg-gray-200 rounded px-4 pt-4"
                  style={{ height: 'calc(100vh - 150px)' }}
                >
                  <div
                    className="p-4 py-4 flex items-center bg-gray-300 text-gray-600 rounded-t font-bold justify-between -mx-4 -mt-4 sticky"
                    style={{ top: '-17px', zIndex: 1 }}
                  >
                    <div className="text-gray-600 font-bold">
                      Catastrophe Configuration
                    </div>
                    <span className="flex-1" />
                    <span className="font-normal text-sm text-gray-500">
                      {dirty ? (
                        'Saving changes...'
                      ) : (
                        <span className="flex items-center">
                          <svg
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            className="w-5 h-5 text-green-500 mr-1"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                          <span className="leading-0">
                            All changes saved automatically
                          </span>
                        </span>
                      )}
                    </span>
                    <button
                      className="ml-2 inline-flex items-center bg-gray-500 hover:bg-gray-600 hover:text-white cursor-pointer text-gray-100 text-xs font-medium uppercase px-2 py-1 rounded-md"
                      onClick={() => setPublishing(true)}
                    >
                      {isPublishing
                        ? 'Publishing... '
                        : 'Publish Configuration'}
                    </button>
                  </div>
                  <div className="flex-1 h-full -mx-1 px-1 pt-4">
                    {state && state.catTypes
                      ? state.catTypes.map((catStep: CatStep, idx: number) => {
                          return (
                            <CatBuilder
                              key={`${idx}-${catStep.label}`}
                              onToggle={() => onToggle({ idx })}
                              catIdx={idx}
                              catStep={catStep}
                              onDeleteClick={onDeleteClick}
                              flowSteps={flowSteps}
                            />
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            </DragDropContext>
          </div>
        </div>
      </main>
    </>
  );
};

export default CatFlow;
