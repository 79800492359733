import { FlowStep } from '../../types';

interface GetFlowStepArgs {
  stepId: string;
  flowSteps: FlowStep[];
}

function getFlowStep({ stepId, flowSteps }: GetFlowStepArgs) {
  return flowSteps.find(flowStep => flowStep.id === stepId);
}

export default getFlowStep;
