import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import './FlowTodo.css';

interface FlowTodoStep {
  /** Main text for the step */
  label: string;
  /** Text anchored underneath the label */
  subText: string;
  /** Unique ID needed for Draggable */
  stepKey: string;
  /** Idx of element */
  idx: number;
}

const FlowTodoStep: React.FC<FlowTodoStep> = ({
  stepKey,
  label,
  subText,
  idx,
}) => {
  return (
    <Draggable key={stepKey} draggableId={stepKey} index={idx}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="bg-gray-50 cursor-grab p-4 rounded shadow w-64 mr-3 mb-4"
          style={{
            ...provided.draggableProps.style,
            height: 130,
          }}
        >
          <div className="text-base text-gray-700 font-bold">{label}</div>
          <div className="mt-1 text-sm text-medium text-gray-500 FlowTodoStep-subText">
            {subText}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default FlowTodoStep;
