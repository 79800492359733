import React from 'react';

import { catastrophes } from '../../consts';
import Card from './Card';

const Cards: React.FC<{}> = ({}) => {
  return (
    <div className="relative">
      <div
        className="absolute right-0 top-0 bottom-0 w-8"
        style={{
          background: `linear-gradient(
                                to left,
                                rgba(249, 250, 251, 1) 0%,
                                rgba(249, 250, 251, 0) 100%
                              )`,
        }}
      />
      <div className="py-2 pl-1 pr-10 -ml-1 -my-2 flex items-start flex-no-wrap overflow-y-scroll gap-10">
        {catastrophes.map(c => (
          <Card key={c.identifier} catastrophe={c} />
        ))}
      </div>
    </div>
  );
};

export default Cards;
